import {
    IonText,   
    IonGrid,
    IonRow,
    IonCol
  } from "@ionic/react";
import React from 'react';

const CertificateRequestsSubmitted: React.FC = () => {
    
    return (
        <IonGrid>                        
            <IonRow>
                <IonCol className="ion-align-self-center">

                    <IonText color="primary">
                        <h1 style={{textAlign: 'center', marginTop: 25}}>กรอกแบบฟอร์มสำเร็จ</h1>
                    </IonText>

                    <IonText>
                        <h4 style={{textAlign: 'center', marginTop: 30}}>เจ้าหน้าที่จะนำข้อมูลที่ท่านกรอก</h4>
                        <h4 style={{textAlign: 'center', marginTop: 20}}>ไปสร้างใบคำขอใบรับรองอิเล็กทรอนิกส์</h4>
                        <h4 style={{textAlign: 'center', marginTop: 20}}>แล้วจัดส่งให้ท่านลงนาม (ภายใน 1 วันทำการ)</h4>
                        <br/>
                    </IonText>

                    <IonText>
                        <h4 style={{textAlign: 'center', marginTop: 30}}><b>กรุณาเตรียมหลักฐานดังต่อไปนี้</b></h4>
                        <h4 style={{textAlign: 'center', marginTop: 20}}>- หนังสือรับรองบริษัท อายุไม่เกิน 3 เดือน (กรรมการลงนามทุกหน้า)</h4>
                        <h4 style={{textAlign: 'center', marginTop: 20}}>- สำเนาบัตรประชาชนกรรมการ พร้อมลงนาม</h4>
                        <br/>
                    </IonText>


                    <IonText>
                        <h4 style={{textAlign: 'center', marginTop: 20}}><b>หากต้องการสอบถาม</b></h4>
                    </IonText>

                    <IonText>
                        <h5 style={{textAlign: 'center', marginTop: 20}}>โทร. 082-579-8555 หรือ Line Id: @Leceipt</h5>
                        <h5 style={{textAlign: 'center', marginTop: 20}}>เวลาทำการ 8:30 - 17:30 น.</h5>
                        <br/>
                    </IonText>


                    <IonText>
                        <h4 style={{textAlign: 'center', marginTop: 20}}><b>ทดลองใช้งานโปรแกรม Leceipt</b></h4>
                    </IonText>

                    <IonText>
                        <div style={{textAlign: 'center', marginTop: 20}}>(e-Tax Invoice & e-Receipt)</div>
                        <h3 style={{textAlign: 'center', marginTop: 20}}><a href="https://www.leceipt.com" target="_blank">www.leceipt.com</a></h3>
                    </IonText>


                </IonCol>
            </IonRow>
        </IonGrid>
    );
};

export default CertificateRequestsSubmitted;
