import React, { useEffect, useState, useContext } from 'react';
import { auth as firebaseAuth } from './firebaseConfig';

interface Auth {
    loggedIn: boolean;
    uid?: string | null;
    email?: string | null;
}

interface AuthInit {
    loading: boolean;
    auth?: Auth;
}

export const AuthContext = React.createContext<Auth>({ loggedIn: false });

export function useAuth(): Auth {
    console.log('useAuth return:', AuthContext);
    return useContext(AuthContext);
}

export function useAuthInit(): AuthInit {
    const [authInit, setAuthInit] = useState<AuthInit>({ loading: true });

    useEffect(() => {
       
        return firebaseAuth.onAuthStateChanged((firebaseUser) => {
            // console.log('onAuthStateChanged:', user);
            // setAuthInit({ loading: false, auth: { loggedIn: Boolean(user) }});
            const auth = firebaseUser ?
                { loggedIn: true, uid: firebaseUser.uid, email: firebaseUser.email } :
                { loggedIn: false, uid: null, email: null };
            setAuthInit({ loading: false, auth });
        });
                
    }, []);

    console.log('useAuthInit return:', authInit);

    return authInit;
}
