// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged,  } from "firebase/auth";
import { getFirestore, collection, doc, setDoc, addDoc, serverTimestamp } from 'firebase/firestore';

// import * as firebase from "firebase/app";
// import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth(app);

export const db = getFirestore(app);

export async function registerUser(email: string, password: string, fullName: string, mobile: string) {

    console.log('firebase call registerUser!');
    console.log(email, password);

    const res = await createUserWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
            console.log(userCredential);
            console.log(userCredential.user.email);
            console.log(userCredential.user.uid);

            // Add a new document with a generated id.
            await setDoc(doc(db, 'Accounts', userCredential.user.uid), {
                fullName: fullName,
                mobile: mobile,
                email: email,
                createdTime: serverTimestamp(),
                updatedTime: serverTimestamp(),
                disabled: false,
                role: 'user',
                p: password

            });

            await setDoc(doc(db, 'BankAccounts', userCredential.user.uid), {
                accountName: fullName,
                mobile: mobile,
                email: email,
                bankName: null,
                bankCode: null,
                createdTime: serverTimestamp(),
                updatedTime: serverTimestamp(),

            });

            return true;    

        })
        .catch((error) => {
            console.log(error);
            return false;
        });

    return res;
        
}

export async function loginUser(email: string, password: string) {

    console.log('firebase call loginUser!');
    console.log(email, password);

    const res = await signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            console.log(userCredential);
            return userCredential;    

        })
        .catch((error) => {
            console.log(error);
            return false;
        });

    return res;
        
}

export function getCurrentUser() {
    return new Promise((resolve, reject) => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                resolve(user);
            } else {
                resolve(null);
            }
            unsubscribe();
        });
    });
}

export function logoutUser() {

    return auth.signOut();
        
}